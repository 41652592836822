<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          src="@/assets/images/logo/logo.png"
          alt="Ladestellenbonus.at"
          style="height:30px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-80 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            style="width:60vw; max-width:900px;"
            src="@/assets/images/background/stromquelle LP.png"
            alt="Ladestellenbonus.at"
          />
          <!--
          <div
            style="
              font-size: 50px;
              font-weight: bold;
              color: rgb(255, 255, 255);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-45%, -50%);
              width: 80%;
              background: rgba(0,0,0,0.35);
              padding: 20px;"
          >
            Holen Sie sich einen Bonus für Ihr Elektrofahrzeug!
            <br>
            <br>
            <div
              style="font-size:20px; line-height:30px;"
            >
              Ladestellenbonus.at ist eine unabhängige Initiative von e-Effizienz.at für mehr Ladestellen in Österreich. Sichern Sie sich eine Rückvergütung für den Strom, den Sie tanken.
            </div>
          </div>
          -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Registrierung
          </b-card-title>

          /* DEMO Java*/
          <br>
          <br>

          <file-pond
            ref="pond"
            name="fileUpload"
            label-idle="Drop files here..."
            allow-multiple="true"
            accepted-file-types="image/jpeg, image/png, application/pdf"
            :server="{
              url: apiUrl,
              process: {
                url: '/mindee/get-image-data',
                method: 'POST',
                /*
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                },
                */
              }
            }"
            allow-file-encode="true"
            allow-file-rename="true"
            allow-image-resize="true"
            image-resize-target-width="1280"
            image-resize-target-height="720"
            image-resize-mode="cover"
            :file-rename-function="fileRenameFunction"
            @:processfile="handleProcessFile"
          />

          <br>
          <br>
          /* DEMO End*/
          <br><br>
          /* DEMO JS */
          <b-form
            @submit.prevent="mindeeSubmit(event)"
          >
            <b-form-file
              id="my-file-input"
              v-model="file1"
              name="file"
              multiple
              :state="Boolean(file1)"
              placeholder="Dateien auswählen oder hier ablegen..."
              browse-text="Suchen"
            />
            <br><br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Send
            </b-button>
          </b-form>
          /* DEMO End*/
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!--
              <b-form-group
                label="First Name"
                label-for="firstName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="firstName"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="first-name"
                      placeholder="First Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Last Name"
                label-for="lastName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="lastName"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="last-name"
                      placeholder="Last Name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              -->
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="benutzer@ladestellenbonus.at"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Fahrzeug-Identifikationsnummer"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>
              -->
              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Sie haben bereits ein Konto?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Anmeldung</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//  import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BFormFile,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vueFilePond from 'vue-filepond'
//  Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import { $authUrl } from '@microServicesConfig'
import router from '@/router'
import store from '@/store/index'

//  Import filepond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,

    BFormFile,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      password: '',
      lastName: '',
      firstName: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      apiUrl: $authUrl,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(() => {
        axios
          .post(`${$authUrl}/auth/register`, {
            username: this.userEmail, password: this.password,
          })
          .then(response => {
            console.log(response)
            if (response.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'User created!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              router.push({ name: 'login' })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'There was a problem creating new user - user already exists!',
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error connecting to server!',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
  },
  setup(props) {
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileRename, FilePondPluginImageResize, FilePondPluginImageTransform)

    console.log(props)

    const mindeeSubmit = () => {
      // . evt.preventDefault()
      const myFileInput = document.getElementById('my-file-input')
      const myFile = myFileInput.files[0]
      if (!myFile) { return }
      const data = new FormData()
      data.append('document', myFile, myFile.name)
      const xhr = new XMLHttpRequest()

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          console.log(this.responseText)
        }
      })

      xhr.open('POST', 'https://api.mindee.net/v1/products/PeterEisenkolb/fin/v1/predict')
      xhr.setRequestHeader('Authorization', 'Token 0724bd60bb2b0886750488dcaa412d59')
      xhr.send(data)
    }

    return {
      FilePond,
      mindeeSubmit,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
