var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"height":"30px"},attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":"Ladestellenbonus.at"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-80 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticStyle:{"width":"60vw","max-width":"900px"},attrs:{"src":require("@/assets/images/background/stromquelle LP.png"),"alt":"Ladestellenbonus.at"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Registrierung ")]),_vm._v(" /* DEMO Java*/ "),_c('br'),_c('br'),_c('file-pond',{ref:"pond",attrs:{"name":"fileUpload","label-idle":"Drop files here...","allow-multiple":"true","accepted-file-types":"image/jpeg, image/png, application/pdf","server":{
            url: _vm.apiUrl,
            process: {
              url: '/mindee/get-image-data',
              method: 'POST',
              /*
              headers: {
                'Authorization': `Bearer ${accessToken}`
              },
              */
            }
          },"allow-file-encode":"true","allow-file-rename":"true","allow-image-resize":"true","image-resize-target-width":"1280","image-resize-target-height":"720","image-resize-mode":"cover","file-rename-function":_vm.fileRenameFunction},on:{":processfile":_vm.handleProcessFile}}),_c('br'),_c('br'),_vm._v(" /* DEMO End*/ "),_c('br'),_c('br'),_vm._v(" /* DEMO JS */ "),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.mindeeSubmit(_vm.event)}}},[_c('b-form-file',{attrs:{"id":"my-file-input","name":"file","multiple":"","state":Boolean(_vm.file1),"placeholder":"Dateien auswählen oder hier ablegen...","browse-text":"Suchen"},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}}),_c('br'),_c('br'),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Send ")])],1),_vm._v(" /* DEMO End*/ "),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"benutzer@ladestellenbonus.at"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Fahrzeug-Identifikationsnummer"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sie haben bereits ein Konto?")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" Anmeldung")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }